import { colors } from '@src/helpers/nails/colors'
import { Swiper, SwiperSlide } from 'swiper/react'
import useSwiperAutoSlide from '@src/hooks/useSwiperAutoSlide'
import { useRef, useState } from 'react'
import Nail from '@src/components/Nail/Nail'
import { COLOR } from '@src/helpers/constants'

const SelectorColor = ({
	onNailSelect,
	disabled
}) => {
	const [selectedId, setSelectedId] = useState(null)

	const handleNailClick = (item) => {
		setSelectedId(item.id)
		if (onNailSelect) onNailSelect({
			type: COLOR,
			data: item
		})
	}

	const swiperRef = useRef(null)
	const slidesPerView = Math.ceil(window.innerWidth / 160)

	useSwiperAutoSlide(swiperRef)

	return (
		<>
			<Swiper
				onSwiper={
					(swiper) => (swiperRef.current = swiper)
				}
				{...{ slidesPerView }}
			>
				{colors.map((item, sectionIndex) => (
					<SwiperSlide key={`${item?.id}-${sectionIndex}`}>
						<Nail
							itemId={item?.id}
							mainColor={item?.main}
							glowColor={item?.glow}
							code={item?.code}
							onClick={() => handleNailClick(item)}
							{...{ selectedId, disabled }}
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</>
	)
}

export default SelectorColor
export const colors = [
	{
		id: 'TEST1',
		main: [170, 170, 170],
		glow: [255, 255, 255],
		code: "10",
		name: "OPI"
	},
	{
		id: 'TEST2',
		main: [136, 46, 67],
		glow: [183, 75, 100],
		code: "84",
		name: "OPI"
	},
	{
		id: 'TEST3',
		main: [255, 218, 140],
		glow: [255, 232, 183],
		code: "62",
		name: "OPI"
	},
	{
		id: 'TEST4',
		main: [21, 175, 93],
		glow: [112, 206, 156],
		code: "74",
		name: "OPI"
	},
	{
		id: 'TEST5',
		main: [188, 79, 253],
		glow: [214, 148, 254],
		code: "58",
		name: "OPI"
	},
	{
		id: 'TEST6',
		main: [255, 154, 179],
		glow: [255, 193, 208],
		code: "32",
		name: "OPI"
	},
	{
		id: 'TEST7',
		main: [200, 54, 90],
		glow: [255, 120, 150],
		code: "67",
		name: "OPI"
	},
	{
		id: 'TEST8',
		main: [40, 180, 160],
		glow: [80, 220, 200],
		code: "41",
		name: "OPI"
	},
	{
		id: 'TEST9',
		main: [255, 90, 60],
		glow: [255, 130, 100],
		code: "95",
		name: "OPI"
	},
	{
		id: 'TEST10',
		main: [100, 255, 80],
		glow: [180, 255, 160],
		code: "83",
		name: "OPI"
	},
	{
		id: 'TEST11',
		main: [160, 200, 255],
		glow: [200, 230, 255],
		code: "21",
		name: "OPI"
	},
	{
		id: 'TEST12',
		main: [180, 150, 200],
		glow: [210, 190, 220],
		code: "72",
		name: "OPI"
	},
	{
		id: 'TEST13',
		main: [90, 30, 200],
		glow: [140, 80, 230],
		code: "56",
		name: "OPI"
	},
	{
		id: 'TEST14',
		main: [255, 80, 170],
		glow: [255, 130, 200],
		code: "34",
		name: "OPI"
	},
	{
		id: 'TEST15',
		main: [60, 255, 255],
		glow: [120, 255, 255],
		code: "18",
		name: "OPI"
	},
	{
		id: 'TEST16',
		main: [140, 255, 100],
		glow: [190, 255, 150],
		code: "93",
		name: "OPI"
	},
	{
		id: 'TEST17',
		main: [220, 110, 190],
		glow: [250, 150, 220],
		code: "12",
		name: "OPI"
	},
	{
		id: 'TEST18',
		main: [50, 100, 255],
		glow: [100, 150, 255],
		code: "65",
		name: "OPI"
	},
	{
		id: 'TEST19',
		main: [180, 255, 70],
		glow: [210, 255, 120],
		code: "38",
		name: "OPI"
	},
	{
		id: 'TEST20',
		main: [90, 140, 200],
		glow: [140, 180, 230],
		code: "27",
		name: "OPI"
	},
	{
		id: 'TEST21',
		main: [200, 100, 50],
		glow: [255, 150, 100],
		code: "11",
		name: "OPI"
	},
	{
		id: 'TEST22',
		main: [70, 170, 240],
		glow: [120, 220, 255],
		code: "49",
		name: "OPI"
	},
	{
		id: 'TEST23',
		main: [255, 200, 150],
		glow: [255, 230, 190],
		code: "61",
		name: "OPI"
	},
	{
		id: 'TEST24',
		main: [30, 255, 130],
		glow: [80, 255, 180],
		code: "53",
		name: "OPI"
	},
	{
		id: 'TEST25',
		main: [255, 160, 50],
		glow: [255, 200, 100],
		code: "88",
		name: "OPI"
	},
	{
		id: 'TEST26',
		main: [120, 255, 180],
		glow: [170, 255, 220],
		code: "76",
		name: "OPI"
	}
]

import SelectorColor from './SelectorColor'
import SelectorShape from './SelectorShape'
import { COLOR, DESIGN, LENGTH, SHAPE } from '@src/helpers/constants'
import SelectorDesign from './SelectorDesign'
import SelectorLength from './SelectorLength'
import 'swiper/css'
import './NailsSelector.scss'

const NailsSelector = ({ mode, onNailSelect, disabled }) => {

	return (
		<div className="NailsSelector">
			{mode === COLOR &&
				<SelectorColor
					{...{
						onNailSelect,
						disabled
					}}
				/>
			}
			{mode === SHAPE &&
				<SelectorShape
					{...{
						onNailSelect,
						disabled
					}}
				/>
			}
			{mode === LENGTH &&
				<SelectorLength
					{...{
						onNailSelect,
						disabled
					}}
				/>
			}
			{mode === DESIGN &&
				<SelectorDesign
					{...{
						onNailSelect,
						disabled
					}}
				/>
			}
		</div>
	)
}

export default NailsSelector

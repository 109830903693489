import { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import useSwiperAutoSlide from '@src/hooks/useSwiperAutoSlide'
import Nail from '@src/components/Nail/Nail'
import { shapes } from '@src/helpers/nails/shapes'
import { SHAPE } from '@src/helpers/constants'

const SelectorShape = ({
	onNailSelect,
	disabled
}) => {
	const [selectedId, setSelectedId] = useState(null)

	const handleNailClick = (item) => {
		setSelectedId(item.id)
		if (onNailSelect) onNailSelect({
			type: SHAPE,
			data: item
		})
	}

	const swiperRef = useRef(null)
	const slidesPerView = Math.ceil(window.innerWidth / 160)

	useSwiperAutoSlide(swiperRef)

	return (
		<Swiper
			onSwiper={
				(swiper) => (swiperRef.current = swiper)
			}
			{...{ slidesPerView }}
		>
			{shapes.map((item, sectionIndex) => (
				<SwiperSlide key={`${item?.id}-${sectionIndex}`}>
					<Nail
						itemId={item?.id}
						image={`/images/nails/shape/${item?.id}.svg`}
						name={item?.id}
						onClick={() => handleNailClick(item)}
						{...{ selectedId, disabled }}
					/>
				</SwiperSlide>
			))}
		</Swiper>
	)
}

export default SelectorShape
import { useCallback } from 'react'
import { captureImageFromVideo } from '@src/utility/camera'
import { SHAPE } from '@src/helpers/constants'

export const useImageCapture = ({
	videoRef,
	canvasRef,
	spaceSize,
	setIsTakenPhoto,
	setIsPhotoMode,
	cameraMode,
	onConstructorModeSelect
}) => {
	const captureImage = useCallback(async () => {
		setIsTakenPhoto(true)
		try {
			await captureImageFromVideo(
				videoRef.current,
				canvasRef.current,
				spaceSize
			)
			setIsPhotoMode(false)
			setIsTakenPhoto(false)
			cameraMode.current = 'canvasMode'
			onConstructorModeSelect(SHAPE)
		} catch (error) {
			console.error('Error capturing image:', error)
		}
	}, [videoRef, canvasRef, spaceSize, setIsTakenPhoto, setIsPhotoMode, cameraMode, onConstructorModeSelect])

	return { captureImage }
}

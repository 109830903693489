export const LOADING   = 'LOADING'
export const IS_LOADED = 'IS_LOADED'
export const FAILED    = 'FAILED'
export const NOT_FOUND = 'NOT_FOUND'

export const EMPTY_LIST = 'EMPTY_LIST'

export const DEFAULT = 'DEFAULT'
export const ANY = 'ANY'

export const FORM  = 'FORM'
export const TABLE = 'TABLE'

export const GET    = 'GET'
export const POST   = 'POST'
export const PUT    = 'PUT'
export const PATCH  = 'PATCH'
export const DELETE = 'DELETE'

export const INFO   = 'INFO'
export const WARN   = 'WARN'
export const DANGER = 'DANGER'

export const LEFT   = 'LEFT'
export const CENTER = 'CENTER'
export const RIGHT  = 'RIGHT'

export const TEXT     = 'TEXT'
export const TEXTAREA = 'TEXTAREA'
export const SELECT   = 'SELECT'
export const DATE     = 'DATE'
export const DAY      = 'DAY'
export const NUMBER   = 'NUMBER'
export const BOOLEAN  = 'BOOLEAN'
export const CHECKBOX = 'CHECKBOX'

export const FILE   = 'FILE'
export const IMAGE  = 'IMAGE'

export const HIDDEN  = 'HIDDEN'

export const ARRAY   = 'ARRAY'
export const OBJECT  = 'OBJECT'
export const INVALID = 'INVALID'

export const UPDATE = 'UPDATE'
export const CREATE = 'CREATE'

export const OK   = 'OK'
export const ATTR = 'ATTR'

export const ON  = 'on'
export const OFF = 'off'

export const PAGESIZE = 'page_size'
export const PAGE     = 'page'

export const EDIT     = 'edit'
export const REMOVE   = 'remove'

export const USER     = 'user'
export const ADMIN    = 'admin'

export const PASSWORD = 'PASSWORD'

export const TOKEN = 'auth_token'
export const USER_ID = 'user_id'
export const USER_DATA = 'user_data'

export const CLIENT_DATA = 'client_data'

export const COLOR = 'color'
export const SHAPE = 'shape'
export const LENGTH = 'length'
export const DESIGN = 'design'

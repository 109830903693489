import { useEffect } from 'react'

const useSwiperAutoSlide = (swiperRef) => {
	useEffect(() => {
		if (swiperRef.current) {
			const initialSpeed = swiperRef.current.params.speed
			const autoSlideSpeed = 400

			swiperRef.current.params.speed = autoSlideSpeed

			swiperRef.current.slideNext()

			setTimeout(() => {
				swiperRef.current.slidePrev()
			}, 600)

			setTimeout(() => {
				swiperRef.current.params.speed = 1200
			}, 500)

			setTimeout(() => {
				swiperRef.current.params.speed = initialSpeed
			}, 700)
		}
	}, [swiperRef])
}

export default useSwiperAutoSlide

import getContrastingColor from '@src/utility/getContrastingColor'
import getNailColor from '@src/utility/getNailColor'
import getRGB from '@src/utility/getRGB'
import { defaultNailGlowRGBA, defaultNailRGBA } from '@src/config'
import './Nail.scss'

const Nail = ({
	itemId,
	mainColor = defaultNailRGBA,
	glowColor = defaultNailGlowRGBA,
	code,
	image,
	name,
	selectedId,
	onClick,
	disabled
}) => {

	const handleClick = () => {
		if (disabled) return
		if (onClick) onClick(itemId)
	}

	let cssClasses = ['Nail']

	if (selectedId && selectedId === itemId)
		cssClasses.push('Nail_active')

	if (disabled)
		cssClasses.push('Nail_disabled')

	if (image)
		cssClasses.push('Nail_imaged')

	return (
		<div
			className={cssClasses.join(' ')}
			onClick={handleClick}
		>
			{image ?
				<div className="Nail__image">
					<img src={image} alt={name} />
				</div>
				:
				<div
					className={
						`Nail__preview ${image ? 'Nail__preview_' + image : ''}`
					}
					style={image ? undefined : getNailColor(mainColor, glowColor)}
				></div>
			}
			<div className="Nail__stick">
				{code &&
					<div
						className="Nail__code"
						style={{
							backgroundColor: getRGB(mainColor),
							color: getContrastingColor(mainColor),
						}}
					>
						{code}
					</div>
				}
				{name &&
					<div className="Nail__name">
						<div className="Nail__name-text">
							{name.replaceAll('_', ' ')}
						</div>
					</div>
				}
			</div>
		</div>
	)
}

export default Nail

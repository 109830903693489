import { url } from '@src/config'
import { ensureTrailingSlash } from './ensureTrailingSlash'

const sanitizeParams = (params) => {
	const sanitizedParams = {}
	for (const [key, value] of Object.entries(params)) {
		const sanitizedKey = key.replace(/\s+/g, ',')
		const sanitizedValue = String(value).replace(/\s+/g, ',')

		sanitizedParams[sanitizedKey] = sanitizedValue
	}
	return sanitizedParams
}

export const getAPI = (path, params) => {
	const baseUrl = ensureTrailingSlash(url.concat(path).join('/'))

	if (params && typeof params === 'object') {
		const sanitizedParams = sanitizeParams(params)
		const searchParams = new URLSearchParams(sanitizedParams)
		return `${baseUrl}?${searchParams.toString()}`
	}

	return baseUrl
}

import { useCallback, useEffect } from 'react'
import { getAPI } from '@src/utility/getAPI'
import { api, GlobalAppConfig } from '@src/config'

export const useTaskResultChecker = ({
	taskId,
	isWaitingResult,
	userToken,
	setIsWaitingResult,
	setTaskId,
	serverImageRef,
	setResultTimeout,
	cameraMode
}) => {
	const checkTaskResult = useCallback((taskId) => {
		if (!taskId) return

		const resultURL = getAPI([api.imageProcessTask, taskId])

		fetch(resultURL, {
			method: 'GET',
			headers: {
				Authorization: `Token ${userToken}`
			}
		})
		.then(response => {
			if (response.status === 404) {
				// Задача ещё в процессе выполнения, повторяем запрос через интервал
				setTimeout(() => {
					if (isWaitingResult) {
						checkTaskResult(taskId)
					}
				}, GlobalAppConfig?.resultRepeatRequest || 2000)
				return null
			} else if (response.status === 200) {
				// Задача завершена — сервер возвращает изображение
				// Получаем бинарные данные в виде blob
				return response.blob()
			} else {
				throw new Error(`Ошибка получения результата: ${response.statusText}`)
			}
		})
		.then(blob => {
			if (blob && serverImageRef?.current) {
				// Создаём временный URL из blob и вставляем в src картинки
				const imageUrl = resultURL
				serverImageRef.current.src = imageUrl
				setIsWaitingResult(false)
				setResultTimeout(false)
				setTaskId(null)
				cameraMode.current = 'imgMode'
			}
		})
		.catch(error => {
			console.error('Ошибка при получении результата:', error)
			alert('Ошибка при обработке изображения.')
			setIsWaitingResult(false)
			setResultTimeout(false)
			setTaskId(null)
		})
	}, [userToken, isWaitingResult, setIsWaitingResult, setTaskId, serverImageRef, setResultTimeout])

	useEffect(() => {
		let initialTimer
		if (taskId && isWaitingResult) {
			initialTimer = setTimeout(() => {
				checkTaskResult(taskId)
			}, GlobalAppConfig?.resultRequestTime || 3500)
		}
		return () => clearTimeout(initialTimer)
	}, [taskId, isWaitingResult, checkTaskResult])

	// Логика с таймером ожидания ответа от сервера (максимальное время ожидания)
	useEffect(() => {
		let timer
		if (isWaitingResult) {
			setResultTimeout(false)
			timer = setTimeout(() => {
				setResultTimeout(true)
				setIsWaitingResult(false)
				setTaskId(null)
			}, GlobalAppConfig?.resultWaitingMaxTime || 8000)
		}
		return () => clearTimeout(timer)
	}, [isWaitingResult, setResultTimeout, setIsWaitingResult, setTaskId])

	return { checkTaskResult }
}

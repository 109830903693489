import { sendImageToServer } from '@src/utility/sendImageToServer'
import { saveImage } from '@src/utility/saveImage'

export const processImage = ({
	canvasRef,
	userToken,
	selected,
	setIsLoading,
	setIsWaitingResult,
	setTaskId
}) => {
	sendImageToServer(
		canvasRef,
		userToken,
		selected,
		setIsLoading,
		setIsWaitingResult,
		setTaskId
	)
}

export const handleSaveImage = ({
	canvasRef,
	userToken,
	clientId,
	onImageSaved
}) => {
	saveImage(
		canvasRef,
		userToken,
		clientId,
		onImageSaved
	)
}

import { api, GlobalAppConfig } from '@src/config'
import { getBlobImageFromCanvas } from '@src/utility/camera'
import { getAPI } from '@src/utility/getAPI'
import { createImageFromBlob } from './createImageFromBlob'
import { resizeImageIfNecessary } from './resizeImageIfNecessary'
import { COLOR, /* LENGTH, */ POST, SHAPE } from '@src/helpers/constants'
import { defaultRGB } from '@src/helpers/vars'

export const sendImageToServer = async (
	canvasRef,
	userToken,
	data,
	setIsLoading,
	setIsWaitingResult,
	setTaskId
) => {
	setIsLoading(true)
	const maxWidth = GlobalAppConfig.maxSentImageWidth || 1280

	try {
		const blob = await getBlobImageFromCanvas(canvasRef.current)
		const image = await createImageFromBlob(blob)
		const resizedBlob = await resizeImageIfNecessary(image, maxWidth)

		const rgb = data?.[COLOR]?.main || defaultRGB
		const colorString = `${rgb[0]} ${rgb[1]} ${rgb[2]}`

		// const response = await fetch(getAPI([api.imageProcessTask]), {
		const response = await fetch(getAPI(
			[api.imageProcessTask],
			{
				[COLOR]: colorString,
				[SHAPE]: data?.[SHAPE]?.id,
			}
		), {
			method: POST,
			headers: {
				Authorization: `Token ${userToken}`,
				'Content-Type': 'application/octet-stream',
				// [LENGTH]: data?.[LENGTH]?.id,
			},
			body: resizedBlob
		})

		if (!response.ok) {
			throw new Error(`Ошибка загрузки изображения: ${response.statusText}`)
		}

		const result = await response.json()

		if (result.task_id) {
			setTaskId(result.task_id)
			setIsWaitingResult(true)
		} else {
			throw new Error('task_id не получен от сервера')
		}
	} catch (error) {
		console.error('Error sending image to server:', error)
		alert('Ошибка при обработке изображения.')
	} finally {
		setIsLoading(false)
	}
}

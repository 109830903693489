import { useRef, useState, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getUserToken } from '@src/services/auth/getUserToken'
import Btn from '@src/components/Btn/Btn'
import PhotoBtn from '@src/components/PhotoBtn/PhotoBtn'
import Overlay from '@src/modules/Overlay/Overlay'
import StudioControll from '@src/components/Studio/StudioControll'
import StudioCamera from '@src/components/Studio/StudioCamera/StudioCamera'
import StudioConstructor from '@src/components/Studio/StudioConstructor'
import Loader from '@src/components/Loader/Loader'
import { getClientData } from '@src/utility/getClientData'
import exitFullScreen from '@src/utility/exitFullScreen'
import { COLOR, LENGTH, SHAPE } from '@src/helpers/constants'
import { useVideoCamera } from '@src/hooks/useVideoCamera'
import { useImageCapture } from '@src/hooks/useImageCapture'
import { useTaskResultChecker } from '@src/hooks/useTaskResultChecker'
import { handleSaveImage, processImage } from '@src/services/imageService'
import './Studio.scss'

const Studio = () => {
	const studioCameraRef = useRef(null)
	const videoRef = useRef(null)
	const canvasRef = useRef(null)
	const serverImageRef = useRef(null)

	const cameraMode = useRef('videoMode')

	const [isLoading, setIsLoading] = useState(false)
	const [isWaitingResult, setIsWaitingResult] = useState(false)

	const [isSaved, setIsSaved] = useState(false)

	const [isPhotoMode, setIsPhotoMode] = useState(true)
	const [isTakenPhoto, setIsTakenPhoto] = useState(false)
	const [isModeSelected, setIsModeSelected] = useState(false)

	const [resultTimeout, setResultTimeout] = useState(false)

	const [taskId, setTaskId] = useState(null)
	const [spaceSize, setSpaceSize] = useState(null)
	const [constructorMode, setConstructorMode] = useState('')

	const navigate = useNavigate()
	const userToken = useMemo(() => getUserToken(), [])

	const onConstructorModeSelect = (value) => {
		setConstructorMode(value)
		setIsModeSelected(true)
	}

	const [selected, setSelected] = useState(null)

	const { isVideoStarted, handleStartVideo, handleStopVideo } = useVideoCamera(
		studioCameraRef,
		videoRef,
		setSpaceSize
	)

	const { captureImage } = useImageCapture({
		videoRef,
		canvasRef,
		spaceSize,
		setIsTakenPhoto,
		setIsPhotoMode,
		cameraMode,
		onConstructorModeSelect
	})

	useTaskResultChecker({
		taskId,
		isWaitingResult,
		userToken,
		setIsWaitingResult,
		setTaskId,
		serverImageRef,
		setResultTimeout,
		cameraMode
	})

	useEffect(() => {
		if (!spaceSize || !videoRef) return
		if (spaceSize?.isHorizontalVideoStream)
			videoRef.current.style.width = '100%'
		else
			videoRef.current.style.height = '100%'
	}, [spaceSize])

	const handleNailSelect = (nail) => {
		const newSelected = {
			...selected,
			[nail?.type]: nail?.data
		}

		setSelected(newSelected)

		if (!newSelected?.[SHAPE]) {
			setTimeout(() => setConstructorMode(SHAPE), 650)
		} else if (!newSelected?.[LENGTH]) {
			setTimeout(() => setConstructorMode(LENGTH), 650)
		} else if (!newSelected?.[COLOR]) {
			setTimeout(() => setConstructorMode(COLOR), 650)
		} else {
			processImage({
				canvasRef,
				userToken,
				selected: newSelected,
				setIsLoading,
				setIsWaitingResult,
				setTaskId
			})
		}
	}

	const backLinkAction = () => {
		if (isModeSelected) {
			setIsModeSelected(false)
			setIsPhotoMode(false)
		} else if (!isPhotoMode && !isModeSelected) {
			setIsPhotoMode(true)
			setIsSaved(false)
			cameraMode.current = 'videoMode'
		} else {
			handleStopVideo()
			exitFullScreen()
			navigate(-1)
		}
	}

	const getBackLinkText = () => {
		if (isModeSelected) return 'К категориям'
		else if (!isPhotoMode && !isModeSelected) return 'Новое фото'
		else return 'На главную'
	}

	const onImageSaved = (isSuccess) => {
		setIsLoading(false)
		if (isSuccess) setIsSaved(true)
	}

	const saveHandleImage = () => {
		const clientData = getClientData()
		if (!clientData || !clientData?.id) {
			alert('Для сохранения необходима авторизация клиента')
			return
		}
		handleSaveImage({
			canvasRef,
			userToken,
			clientId: clientData.id,
			onImageSaved
		})
	}

	const cancelWaiting = () => {
		setResultTimeout(false)
		setIsWaitingResult(false)
		setTaskId(null)
	}

	return (
		<div className="Studio">
			<StudioControll
				backLinkText={getBackLinkText()}
				{...{
					backLinkAction,
					isPhotoMode,
					setIsPhotoMode,
				}}
			>
				{cameraMode?.current === 'canvasMode' &&
					<Btn
						label={isSaved ? 'Сохранено' : 'Сохранить результат'}
						type="light"
						onClick={saveHandleImage}
						disabled={isSaved || isLoading || isWaitingResult}
					/>
				}
			</StudioControll>

			{!isVideoStarted &&
				<Overlay>
					<Btn
						type="light"
						label="Включить камеру"
						onClick={handleStartVideo}
					/>
				</Overlay>
			}

			{resultTimeout ? (
				<Overlay>
					<div style={{textAlign: 'center'}}>
						<p>Время ожидания ответа превышено</p>
						<br />
						<Btn
							type="glass"
							label="Закрыть"
							onClick={cancelWaiting}
						/>
					</div>
				</Overlay>
			) : isLoading ? (
				<Loader text="Загрузка фотографии…" />
			) : isWaitingResult ? (
				<Loader text="Обработка фотографии…" />
			) : ''}

			<StudioCamera
				mode={cameraMode.current}
				blured={isWaitingResult || isLoading || isTakenPhoto}
				{...{
					studioCameraRef,
					videoRef,
					canvasRef,
					serverImageRef
				}}
			/>
			<div className="Studio__panel">
				{isPhotoMode ? (
					<PhotoBtn
						onClick={captureImage}
						hold={isTakenPhoto}
					/>
				) : (
					<StudioConstructor
						onNailSelect={handleNailSelect}
						disabled={isLoading || isWaitingResult}
						mode={constructorMode}
						onModeSelect={onConstructorModeSelect}
						{...{isModeSelected}}
					/>
				)}
			</div>
		</div>
	)
}

export default Studio

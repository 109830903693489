export const shapes = [
	{
		id: 'square'
	},
	{
		id: 'soft_square'
	},
	{
		id: 'circle'
	},
	{
		id: 'almond'
	},
	{
		id: 'stilet'
	},
	{
		id: 'pike'
	},
	{
		id: 'ballet'
	}
]

import NailsSelector from '../NailsSelector/NailsSelector'
import ActionBtn from './ActionBtn'
import { COLOR, DESIGN, SHAPE, LENGTH } from '@src/helpers/constants'
import './StudioConstructor.scss'

const StudioConstructor = ({
	isModeSelected,
	mode,
	onNailSelect,
	onModeSelect,
	disabled
}) => {

	return (
		<div className="StudioConstructor">
			{isModeSelected ? (
				<NailsSelector {...{ mode, onNailSelect, disabled}} />
			) : (
				<div className="StudioConstructor_btns">
					<ActionBtn
						text="Форма"
						onClick={() => onModeSelect(SHAPE)}
					/>
					<ActionBtn
						text="Длина"
						onClick={() => onModeSelect(LENGTH)}
					/>
					<ActionBtn
						text="Цвет"
						onClick={() => onModeSelect(COLOR)}
					/>
					<ActionBtn
						text="Дизайн"
						onClick={() => onModeSelect(DESIGN)}
						disabled
					/>
				</div>
			)}
		</div>
	)
}

export default StudioConstructor

import { useState, useCallback } from 'react'
import enterFullScreen from '@src/utility/enterFuulScreen'
import exitFullScreen from '@src/utility/exitFullScreen'
import { startVideo } from '@src/utility/startVideo'

export const useVideoCamera = (studioCameraRef, videoRef, setSpaceSize) => {
	const [isVideoStarted, setIsVideoStarted] = useState(false)
	const [stopVideoFunction, setStopVideoFunction] = useState(null)
	
	const handleStartVideo = useCallback(async () => {
		enterFullScreen()
		try {
			const stopVideo = await startVideo(
				videoRef,
				studioCameraRef,
				setIsVideoStarted,
				setSpaceSize
			)
			setStopVideoFunction(() => stopVideo)
		} catch (err) {
			console.error('Ошибка при запуске видео:', err)
		}
	}, [videoRef, studioCameraRef, setSpaceSize])

	const handleStopVideo = useCallback(() => {
		if (stopVideoFunction) {
			stopVideoFunction()
			setStopVideoFunction(null)
			exitFullScreen()
			setIsVideoStarted(false)
		}
	}, [stopVideoFunction])

	return {
		isVideoStarted,
		handleStartVideo,
		handleStopVideo
	}
}
